import React, { useState, useEffect } from 'react';
import { Select, Modal, Table, message, Pagination } from 'antd';
import { getClass, createUser, getCurrentUser, updateUserStatus } from './utils/request';

import styles from '../src/css/ManageUsers.module.scss'
import TabBar from './components/TabBar';

function ManageUser() {

  const [openModal, setOpenModal] = useState(false)
  const [classInfo, setClassInfo] = useState()
  const [name, setName] = useState('')
  const [classId, setClassId] = useState()
  const [email, setEmail] = useState('')
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [userInfo, setUserInfo] = useState()
  const [pageCount, setPageCount] = useState()
  const [classFilter, setClassFilter] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getClassInfo()
  }, [])

  useEffect(() => {
    getUser()
  }, [page, classFilter, pageLimit])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }

  const getUser = async () => {
    setLoading(true)
    const userInfo = await getCurrentUser({
      page: page,
      pageLimit: pageLimit,
      ...classFilter
    })
    setUserInfo(userInfo.data.data)
    setPageCount(userInfo.data.count)
    setLoading(false)
  }

  const classOptions = []
  classInfo && classInfo.map((item) => {
    classOptions.push({
      value: item.name,
      label: item.name
    })
  })

  const showHint = () => {
    if(!name) {
      message.open({
        type: 'warning',
        content: 'Please input user name.',
      })
    } else if (!classId) {
      message.open({
        type: 'warning',
        content: 'Please select a class.',
      })
    }else if (!email) {
      message.open({
        type: 'warning',
        content: 'Please input email.',
      })
    }
  }

  const createUserFunction = async() => {
    const result = await createUser({
      name: name,
      class: classId,
      email: email,
    })
    if (result) {
      getUser()
      setName('')
      setEmail('')
    }
  }

  const banUser = async (item) => {
    const result = await updateUserStatus({ email: item.email, locked: true})
    if (result) {
      getUser()
    }
  }

  const allowUser = async (item) => {
    const result = await updateUserStatus({ email: item.email, locked: false})
    if (result) {
      getUser()
    }
  }

  const columns = [
    {
      title: 'Name',
      width: 200,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'classId',
      width: 150,
      filters: classInfo && classInfo.map(item => ({ text: item.name, value: item.name })),
      filterMultiple: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
    },
  ]

  const data = []

  userInfo && userInfo.map((item, index) => {
    data.push({
      key: index,
      name: item.username,
      class: item.class,
      email: item.email,
      password: item.password,
      status: item.locked ? "Banned" : "Allowed",
      operation:
      <div className={styles.operation}>
        {
          item.locked ? <div className={styles.allow} onClick={item.locked ? () => allowUser(item) : () => {}}>Allow</div>
          :  <div className={styles.ban} onClick={item.locked ? () => {} : () => banUser(item)}>Ban</div>
        }
      </div>
    });
  })

  const canCreate = name && classId && email

  return (
    <>
      <TabBar />
      <div className={styles.page}>
        <div className={styles.title}>Manage Users</div>
        <div className={styles.add}>
          <div className={styles.addUser} onClick={() => setOpenModal(true)}>Add a new user</div>
        </div>

        <Table
          className={styles.table}
          columns={columns}
          dataSource={data}
          sticky
          bordered
          pagination={false}
          loading={loading}
          onChange={ (pagination, filters) => setClassFilter(filters)}
        />
        <div className={styles.pagination}>
          <Pagination
            current={page}
            total={pageCount}
            showTotal={(total) => `Total ${total} items`}
            onChange={(page) => setPage(page)}
            pageSizeOptions={[10, 20, 50]}
            showSizeChanger
            onShowSizeChange={(current, pageSize) => {
              setPageLimit(pageSize)
            }}
          />
        </div>

        <Modal
          open={openModal}
          centered
          maskClosable={true}
          closable={true}
          footer={null}
          onCancel={() => setOpenModal(false)}
        >
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.name}>
                <span className={styles.userInfo}>Name: </span>
                <input className={styles.input} value={name} onChange={(e) => setName(e.target.value)}/>
              </div>
              <div className={styles.class}>
                <span className={styles.userInfo}>Class: </span>
                <Select
                  options={classOptions}
                  className={styles.selector}
                  onChange={(value) => setClassId(value)}
                  value={classId}
                />
              </div>
              <div className={styles.email}>
                <span className={styles.userInfo}>Email: </span>
                <input className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className={styles.email}>
                Upon creation, a notification email will be sent to the user.
              </div>
            </div>
           
            <div className={styles.btnGroup}>
                <div className={canCreate ? styles.modalConfirm : styles.disabled} onClick={ canCreate ? createUserFunction : showHint} >Create</div>
                <div className={styles.modalCancel} onClick={() => setOpenModal(false)} >Cancel</div>
              </div>
          </div>
        </Modal>

      </div>
    </>
  )

  
}


export default ManageUser;