import React from 'react';
import { Steps } from 'antd'
import styles from '../css/ProjectSteps.module.css'
import { observer } from 'mobx-react'
import projectStore from '../store/projectStore';

function ProjectSteps (props) {

  const handleChange = (index) => {
    console.log(index, projectStore.project.step)
    if(index <= projectStore.project.step) {
      if (projectStore.project.isCompleted) {
        projectStore.curStep = index
      } else if (projectStore.curStep > index) {
        projectStore.curStep = index
      }
    }
  }

  return (
    <div className={styles.steps} >
      <Steps
        destroyInactive
        current={projectStore.curStep}
        onChange={handleChange}
        className={styles.step}
        items={[
          {
            title: 'Reference Annotating',
          },
          {
            title: 'Reference Categorizing',
          },
          {
            title: 'Reference Mapping',
          },{
            title: 'Review Writing',
          },
        ]}
      />
    </div>
  )
  
}

export default observer(ProjectSteps)