import axios, {isCancel, AxiosError} from 'axios';
import { message } from 'antd'
import userStore from '../store/userStore';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    console.log('token', token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  }, function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
    if (response.data) {
      if (response.data && response.data.code === 401) {
        console.log('未登录')
        localStorage.removeItem('token')
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
        // TODO: redirect to login page
        // throw new Response("", { status: 401 });
        // message.error(response.data.msg)
        return Promise.reject('未登录');
      }
      if (response.data.code && response.data.code !== 0) {
        console.log('后台报错', response)
        message.error(response.data.msg)
        return Promise.reject(response.data.msg);
      }
      if (response.data && response.data.data && response.data.data.addCoin) {
        message.open({
          type: 'success',
          content: `Congratulations! You have earned ${response.data.data.addCoin} more A-coins!`
        })
      }
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.status === 401) {
        console.log('未登录')
        localStorage.removeItem('token')
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
        // TODO: redirect to login page
        // throw new Response("", { status: 401 });
        // redirect('login')
    } else {
      message.error(error.message)
    }
    return Promise.reject(error);
  });

  userStore.refresh()

  export async function userLogin(data) {
    const result = await axios.post('/v1/auth/login', data)

    console.log('!!!', result)
    
    if (result.code === 0) {
        // 登录成功
        localStorage.setItem('token', result.data.access_token)
        const user = await userStore.refresh()
        return user
    }
    return result
  }

  export function getUserIssues() {
    return axios.get('/v1/issue/getUserIssues')
  }

  export function getIssueCountries(data) {
    return axios.post('/v1/country/getIssueCountries', data)
  }

  export function getCountryIdeas(data) {
    return axios.post('/v1/idea/getCountryIdeas', data)
  }

  export function cancelLike(data) {
    return axios.post('/v1/like/cancelLike', data)
  }
  
  export function getTestProfiles() {
    return axios.get('/v1/test/getTestProfiles')
  }

  export function getTestAnswers(data) {
    return axios.post('/v1/test/getTestAnswers', data)
  }

  export function getTestQuestions(data) {
    return axios.post('/v1/test/getTestQuestions', data)
  }

  export async function submitTestAnswer(data) {
    const result = await axios.post('/v1/test/submitTestAnswer', data)
    userStore.refresh()
    return result
  }

  export function getLeaderBoard() {
    return axios.get('/v1/auth/leaderboard')
  }

  export function getProfile() {
    return axios.get('/v1/auth/profile')
  }

  export function getMyReadings() {
    return axios.get('/v1/reading/getMyReadings')
  }

  export function getMyTasks() {
    return axios.get('/v1/task/getMyTasks')
  }

  export async function createComment(data) {
    const result = await axios.post('/v1/comment/createComment', data)
    userStore.refresh()
    return result
  }

  export function joinCountry(data) {
    return axios.post('/v1/country/joinCountry', data)
  }

  export async function createIdea(data) {
    const result = await axios.post('/v1/idea/createIdea', data)
    userStore.refresh()
    return result
  }

  export function getProposal(data) {
    return axios.post('/v1/proposal/getProposal', data)
  }

  export function getMyProposal(data) {
    return axios.post('/v1/proposal/getMyProposal', data)
  }

  export function saveProposal(data) {
    return axios.post('/v1/proposal/saveProposal', data)
  }

  export async function submitProposal(data) {
    const result = await axios.post('/v1/proposal/submitProposal', data)
    userStore.refresh()
    return result
  }

  export function createProposal(data) {
    return axios.post('/v1/proposal/createProposal', data)
  }

  export function downloadMyProposal(data) {
    return axios.post('/v1/proposal/downloadMyProposal', data)
  }

  export function getEvaluationList(data) {
    return axios.post('/v1/evaluation/getEvaluationList', data)
  }

  export async function submitEvaluation(data) {
    const result = await axios.post('/v1/evaluation/submitEvaluation', data)
    userStore.refresh()
    return result
  }

  export function modifyPassword(data) {
    return axios.post('/v1/auth/modifyPassword', data)
  }

  export function getClass() {
    return axios.get('/v1/class')
  }

  export function createUser(data) {
    return axios.post('/v1/auth/createUser', data)
  }

  export function getCurrentUser(data) {
    return axios.post('/v1/auth/getCurrentUser', data)
  }

  export function updateUserStatus(data) {
    return axios.post('/v1/auth/updateStatus', data)
  }

  export function getUserDataReq(data) {
    return axios.post('/v1/auth/getUserData', data)
  }

  export function getUserDetail(data) {
    return axios.post('/v1/auth/getUserDetail', data)
  }

  export function createPlan(data) {
    return axios.post('/v1/plan/createPlan', data)
  }

  export function getMyProjects() {
    return axios.get('/v1/project/getMyProjects')
  }

  export function createProject(data) {
    return axios.post('/v1/project/createProject', data)
  }

  export function deleteMyProject(projectId) {
    return axios.get('/v1/project/deleteMyProject', { params: { projectId } })
  }

  export function getProjectDetail(projectId) {
    return axios.get('/v1/project/getProjectDetail', { params: { projectId } })
  }

  export function addProjectRef(projectId) {
    return axios.get('/v1/project/addProjectRef', { params: { projectId } })
  }

  export function askRefHelp(data) {
    return axios.post('/v1/project/askRefHelp', data)
  }

  export function askMindHelp(data) {
    return axios.post('/v1/project/askMindHelp', data)
  }

  export function askMoveHelp(data) {
    return axios.post('/v1/project/askMoveHelp', data)
  }


  export function submitRefs(data) {
    return axios.post('/v1/project/submitRefs', data)
  }


  export function submitMoves(data) {
    return axios.post('/v1/project/submitMoves', data)
  }

  export function addKeyConcept(projectId) {
    return axios.get('/v1/project/addKeyConcept', { params: { projectId } })
  }

  export function submitConcepts(data) {
    return axios.post('/v1/project/submitConcepts', data)
  }

  export function updateMindMapData(data) {
    return axios.post('/v1/project/updateMindMapData', data)
  }

  export function submitMindMap(projectId) {
    return axios.get('/v1/project/submitMindMap', { params: { projectId } })
  }

  export function getProjectDownload(projectId) {
    return axios.get('/v1/project/getProjectDownload', { params: { projectId } })
  }

  export function removeKeyConcept(data) {
    return axios.post('/v1/project/removeKeyConcept', data)
  }
