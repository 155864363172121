import {Button, Col, Row, Space, Tooltip} from 'antd';
import React, {FC, useState} from 'react';
import {SvgIcons} from './SvgIcons';
import { PlusCircleOutlined, MinusCircleOutlined,ColumnHeightOutlined,DownloadOutlined , MinusOutlined,RightSquareOutlined, LeftSquareOutlined, ForwardOutlined, BackwardOutlined, PlusOutlined  } from '@ant-design/icons';
import styles from '../../css/MindMap.module.scss';

const Toolbar = (props) => {
  const { editor, name } = props;

  const [isStart, setIsStart] = useState(true)
  const [isEnd, setIsEnd] = useState(true)

  editor && editor.on('back_forward', (index, len) => {
    setIsStart(index <= 0)
    setIsEnd(index >= len - 1)
  })

  return (
    <Row gutter={16} className={styles.toolbar}>
      <Col span={12}>
        <Space>

        <Tooltip title="undo">
            <Button onClick={() => {
              editor.execCommand('BACK')
            }} 
            icon={<BackwardOutlined />}></Button>
          </Tooltip>

          <Tooltip title="redo">
            <Button onClick={() => {
              editor.execCommand('FORWARD')
            }}
            icon={<ForwardOutlined />}></Button>
          </Tooltip>

          <Tooltip title="delete node ([Del])">
            <Button onClick={
              () => editor.execCommand('REMOVE_NODE')
            } icon={<MinusOutlined />}></Button>
          </Tooltip>

          <Tooltip title="insert child node ([Tab])">
            <Button onClick={
              () => editor.execCommand('INSERT_CHILD_NODE')
            } icon={<PlusOutlined />}></Button>
          </Tooltip>

          <Tooltip title="insert node ([Enter])">
            <Button onClick={
              () => editor.execCommand('INSERT_NODE')
            } icon={<ColumnHeightOutlined />}></Button>
          </Tooltip>

          {/* <Tooltip title="download">
            <Button onClick={
              async () => {
                let data = await editor.doExport.png()
                let a = document.createElement('a')
                a.href = data// .png、.svg、.pdf、.md、.json、.smm
                a.download = `${name}.png`
                
                a.click()
              }
            } icon={<DownloadOutlined />}></Button>
          </Tooltip> */}
          
        </Space>
      </Col>
      <Col span={4}></Col>
    </Row>
  );
};

export default Toolbar;