import { useEffect, useRef, useState } from 'react';
import MindMap from 'simple-mind-map';
import Themes from 'simple-mind-map-plugin-themes'
import Toolbar from './MindMap/Toolbar';
import styles from '../css/MindMap.module.scss';
import { useUnmount } from 'react-use';
import Export from 'simple-mind-map/src/plugins/Export.js'
import Drag from 'simple-mind-map/src/plugins/Drag.js'
import ExportPDF from 'simple-mind-map/src/plugins/ExportPDF.js'

Themes.init(MindMap)

export default function Mind(props){
  const ref = useRef(null);
  const [editor, setEditor] = useState();
  MindMap.usePlugin(Export)
  MindMap.usePlugin(Drag)
  MindMap.usePlugin(ExportPDF)

  useUnmount(() => {
    editor && editor.destroy();
  })

  let mindMapInstance;
  useEffect(() => {
    const data = props.value
      ? props.value
      : {
          data: {
            text: 'Root Node',
          },
          children: [
            {
              data: {
                text: 'Child Node',
              },
            },
          ],
        };
        
    const defaultParams = {
      el: ref.current,
      readonly: props.disabled,
      data,
      layout: 'logicalStructure',
      enableAutoEnterTextEditWhenKeydown: true,
      nodeTextEditZIndex: 1000,
      nodeNoteTooltipZIndex: 1000,
      theme: 'dark7',
      lineStyle: 'curve',
      defaultInsertSecondLevelNodeText: 'Child Node',
      defaultInsertBelowSecondLevelNodeText: 'Child Node',
      initRootNodePosition: ['10%', '45%'],
    };

    mindMapInstance = new MindMap(defaultParams);
    mindMapInstance.on('data_change', (data) => {
      props.onChange(data);
    });

    setEditor(mindMapInstance);
  }, []);
  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
        <Toolbar name={props.rootName} editor={editor} className={styles.toolbar} />
    </div>
  );
};