import React, { useState, useEffect } from 'react';
import  { Button, message, Tag } from 'antd';
import { observer } from "mobx-react";
import { PlusOutlined } from '@ant-design/icons';
import styles from '../css/ConceptDetail.module.scss';
import projectStore from '../store/projectStore';
import ConceptTags from './ConceptTags';
import { CloseCircleOutlined } from '@ant-design/icons';

function ConceptDetail(props) {
    const { refTags, keyConcept, disabled } = props

    const removeSubConcept = (index) => {
      keyConcept.subConcepts.splice(index, 1)
    }

    console.log(keyConcept.relRefs, keyConcept.defRefs)

    return (
      <div className={styles.body}>
        <div className={styles.addSubConcept}>
          {
            !disabled && <Button onClick={projectStore.addSubConcept} className={styles.addSubConcept} icon={<PlusOutlined />}>Add one more subconcept</Button>
          }
        </div>
        <div className={styles.input}>
          <input placeholder='（Word limit of 20 words）' disabled={disabled} onChange={(e) => keyConcept.title = e.target.value} value={keyConcept.title}/>
        </div>
        <div className={styles.definition}>
          <div className={styles.title}>Definition reference(s):</div>
          <ConceptTags disabled={disabled} refTags={refTags} value={keyConcept.defRefs} onChange={value => keyConcept.defRefs = value}/>
        </div>
        <div className={styles.relevant}>
          <div className={styles.title}>Relevant reference(s):<br />(especially empirical ones)</div>
          <ConceptTags disabled={disabled} refTags={refTags} value={keyConcept.relRefs} onChange={value => keyConcept.relRefs = value}/>
        </div>
        <div className={styles.subConceptGroup}>
          {
            keyConcept.subConcepts.map((subConcept, index) => {
              return (
                <div key={index} className={styles.subConcept}>
                  <div className={styles.item}>
                    <div className={styles.right}>
                      <div className={styles.input}>
                        <div className={styles.titleBox}><div className={styles.title}>{`subconcept ${index + 1}`}</div>
                          <div onClick={() => {
                            removeSubConcept(index)  
                          }} className={styles.close}>
                            <CloseCircleOutlined />
                          </div>
                        </div>
                        <input placeholder='（Word limit of 20 words）' onChange={(e) => subConcept.title = e.target.value} value={subConcept.title}/>
                      </div>
                      <div className={styles.subDefinition}>
                        <div className={styles.title}>Definition reference(s):</div>
                        <ConceptTags disabled={disabled} refTags={refTags} value={subConcept.defRefs} onChange={value => subConcept.defRefs = value}/>
                      </div>
                      <div className={styles.subDefinition}>
                        <div className={styles.title}>Relevant reference(s):</div>
                        <ConceptTags disabled={disabled} refTags={refTags} value={subConcept.relRefs} onChange={value => subConcept.relRefs = value}/>
                      </div>
                    </div>
                </div>
                </div>
              )
            })
          }
        </div>
      </div>
      
    )
}


export default observer(ConceptDetail);