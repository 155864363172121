import { makeAutoObservable } from "mobx";
import { getProjectDetail } from "../utils/request";
import { addProjectRef, askRefHelp, submitRefs, addKeyConcept, submitConcepts, updateMindMapData, askMindHelp, submitMindMap, askMoveHelp, submitMoves, getProjectDownload, removeKeyConcept } from "../utils/request";
import { message } from "antd";
import { countWords } from "../utils/utils";

const processData = (project) => {
  if (!project.researchQuestions || project.researchQuestions.length === 0) {
    project.researchQuestions = ['']
  }
  return project
}

// Todo 總數據源
class ProjectStore {

  project = null
  loading = false
  submitLoading = false
  aiLoading = false

  refTabIndex = '0'
  conceptTabIndex = '0'
  curStep = 0

  downloadTitle = ''
  downloadContent = ''

  constructor() {
    makeAutoObservable(this);
  }

  async fetchProjectDetail(id) {
    this.project = null
    this.refTabIndex = '0'
    this.conceptTabIndex = '0'
    this.curStep = 0

    this.loading = true
    const result = await getProjectDetail(id)
    this.project = processData(result.data)
    this.curStep = result.data.step
    this.loading = false
  }
 
  addQuestion = () => {
    this.project.researchQuestions.push('')
  }

  addRef = async () => {
    this.loading = true
    const result = await addProjectRef(this.project.id)
    this.project.projectRefs = result.data.projectRefs
    this.loading = false
    this.refTabIndex = (result.data.projectRefs.length - 1).toString()
  }

  addKeyConcept = async () => {
    this.loading = true
    const result = await addKeyConcept(this.project.id)
    this.project.keyConcepts.push(result.data)
    this.conceptTabIndex = (this.project.keyConcepts.length - 1).toString()
    this.loading = false
  }

  addSubConcept = () => {
    this.project.keyConcepts[this.conceptTabIndex].subConcepts.push({
      title: '',
      defRefs: [],
      relRefs: []
    })
  }

  provideFeedback = async () => {
    this.aiLoading = true
    const ref = this.project.projectRefs[this.refTabIndex]
    try{
      const result = await askRefHelp({
        projectId: this.project.id,
        refId: ref.id,
        summary: ref.summary,
        researchQuestions: this.project.researchQuestions,
        researchTopic: this.project.researchTopic
      })
      this.aiLoading = false
      this.project.refHelps = result.data.refHelps
      this.project.refLeft = result.data.refLeft
    } catch(e) {
      this.aiLoading = false
    }
  }

  askMindHelp = async () => {
    this.aiLoading = true
    try{
      const result = await askMindHelp({
        projectId: this.project.id,
      })
      this.aiLoading = false
      this.project.mindHelps = result.data.mindHelps
      this.project.mindLeft = result.data.mindLeft
    } catch(e) {
      this.aiLoading = false
    }
  }

  askMoveHelp = async () => {
    this.aiLoading = true
    try{
      const result = await askMoveHelp({
        projectId: this.project.id,
        moves: this.project.moves
      })
      this.aiLoading = false
      this.project.moveHelps = result.data.moveHelps
      this.project.moveLeft = result.data.moveLeft
    } catch(e) {
      this.aiLoading = false
    }
  }

  submitRefs = async () => {

    if (countWords(this.project.researchTopic) > 20) {
      message.error('Research topic should be less than 20 words')
      return
    }
    const errQuestion = this.project.researchQuestions.find(question => countWords(question) > 50)
    if (errQuestion) {
      message.error('Research question should be less than 50 words')
      return
    }
    
    if (this.project.refLeft === this.project.projectRefs.length * 3) {
      message.error('Please ask for feedback at least once before submitting')
      return
    }

    this.submitLoading = true

    try {
      const result = await submitRefs({
        projectId: this.project.id,
        projectRefs: this.project.projectRefs,
        researchTopic: this.project.researchTopic,
        researchQuestions: this.project.researchQuestions
      })
      this.submitLoading = false

      this.project.projectRefs = result.data.projectRefs
      this.project.step = result.data.step

      this.curStep = 1
    } catch(e) {
      this.submitLoading = false
    }
  }

  submitConcepts = async () => {
    const errorItem = this.project.keyConcepts.find(keyConcept => {
      return countWords(keyConcept.title) > 20 || keyConcept.subConcepts.find(subConcept => countWords(subConcept.title) > 20)
    })
    if (errorItem) {
      message.error('Key concept and sub concept should be less than 20 words')
      return
    }
    try {
      this.submitLoading = true
  
      const result = await submitConcepts({
        projectId: this.project.id,
        keyConcepts: this.project.keyConcepts
      })
  
      this.submitLoading = false
      this.project.step = result.data.step
      this.curStep = 2
    } catch(e) {
      console.log(e)
      this.submitLoading = false
    }
  }

  submitMoves = async () => {
    if (this.project.moveLeft === 3) {
      message.error('Please ask for feedback at least once before submitting')
      return
    }

    this.submitLoading = true
    const result = await submitMoves({
      projectId: this.project.id,
      moves: this.project.moves
    })
    this.project.step = result.data.step
    // this.curStep = result.data.step
    this.submitLoading = false

    // navigateTo
  }

  updateMindMap = (mindMap) => {
    if (this.project) {
      
      this.project.mindMapData = mindMap
      updateMindMapData({
        projectId: this.project.id,
        mindMapData: mindMap
      })

    }
  }

  submitMindMap = async () => {
    if (this.project.mindLeft === 3) {
      message.error('Please ask for feedback at least once before submitting')
      return
    }

    this.submitLoading = true
    const result = await submitMindMap(this.project.id)
    this.project.step = result.data.step
    this.curStep = result.data.step
    this.submitLoading = false
  }

  addMove = (key) => {
    this.project.moves.push({
      type: key,
      content: ''
    })
  }

  deleteMove = (index) => {
    this.project.moves.splice(index, 1)
  }

  fetchProjectDownload = async (id) => {
    this.loading = true
    const result = await getProjectDownload(id)
    this.downloadTitle = result.data.title
    this.downloadContent = result.data.content
    this.loading = false
  }

  removeKeyconcept = async (index) => {
    const keyConcept = this.project.keyConcepts[index]
    this.loading = true

    const savedKeyConcepts = this.project.keyConcepts.filter((_, i) => i.toString() !== index) 

    const result = await removeKeyConcept({
      projectId: this.project.id,
      keyConceptId: keyConcept.id,
      savedKeyConcepts: savedKeyConcepts
    })
    this.project.keyConcepts = result.data.keyConcepts
    this.loading = false
  }
  
}

const projectStore = new ProjectStore()

export default projectStore;
